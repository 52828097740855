/** @jsx jsx */
import Header from "../components/header"
import * as H from "../components/headings"
import * as C from "../components/containers"
import { graphql, Link, useStaticQuery } from "gatsby"
import { jsx } from "theme-ui"

const BlogItem = props => (
  <span>
    <Link
      {...props}
      sx={{
        color: "text",
        fontFamily: "heading",
        fontSize: "2rem",
        textDecoration: "none",
        "&:hover": {
          color: "secondary",
        },
        height: "100%",
      }}
    />
    </span>
)

export default () => {
  const data = useStaticQuery(graphql`query {
      allMarkdownRemark {
          edges {
              node {
                  frontmatter {
                      path
                      title
                      date(formatString: "MMMM DD, YYYY")
                  }
              }
          }
      }
  }`)

  return (
    <div>
      <Header/>
      <C.centered sx={{pr: "2rem"}}>
        <H.h1>Blog</H.h1>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <BlogItem sx={{fontSize: [3, 5, 5, 6]}}
            to={node.frontmatter.path}>{node.frontmatter.title} - {node.frontmatter.date}</BlogItem>))}
      </C.centered>
    </div>
  )
}